import { useEffect, useState } from "react";
import { DropProperties, FilterPropertiesService, PublishService } from "../Services/Serv-properties";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Pagination, Snackbar } from "@mui/material";
import UpdateProperty from "./update-property";
import CheckboxListing from "./checkbox-listing";


const Listing: React.FunctionComponent<{}> = () => {

    const [listProperties, setListProperties] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [filterProperty, setFilterProperty] = useState<any>({
        Address: {
            city: ""
        },
        category: "",
        offeringType: "",
        price: "",
        size: "",
        bedrooms_count: "",
        bathrooms_count: "",
        amenities: [],
        keyWord: ""
    });
    const [propertyId, setPopertyId] = useState('');
    const [page, setPage] = useState<number>(1);
    const [showMessage, setShowMessage] = useState<any>();
    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [openDrop, setOpenDrop] = useState(false);
    const [openPublish, setOpenPublish] = useState(false);
    const [change, setChange] = useState(false);


    useEffect(() => {
        FilterPropertiesService(page, filterProperty).then((data: any) => {
            setListProperties(data.properties)
            setLoading(false)
        }).catch((error: any) => {
            console.log("error")
        })

    }, [change])

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        FilterPropertiesService(value, filterProperty).then((data) => {
            setPage(Math.ceil(data.totalItems / 2))
            setListProperties(data.properties)
        }).catch(err => {

        })

    };
    const drop = () => {
        setOpenDrop(false);
        DropProperties(propertyId).then((data) => {
            setOpen(true);
            setChange(!change)
            setShowMessage("Property deleted successfully!")
        }).catch((error) => {
            setOpenError(true);
            setShowMessage("Failed to delete property !")
        })
    }
    const handleCloseDrop = () => {
        setOpenDrop(false);
    };


    const handleClosePublish = () => {
        setOpenPublish(false);
    };

    const dropProperty = (id: any) => {
        setPopertyId(id);
        setOpenDrop(true);

    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleCloseError = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    }

    const UpdatePropertyHandler = (property: any) => {

    }

    const publishProperty = (id: any) => {
        setPopertyId(id);
        setOpenPublish(true);

    }

    const Publish = () => {
        PublishService(propertyId).then((resp) => {
            setChange(!change)
            setOpenPublish(false)
            setOpen(true);
            setShowMessage("Property published successfully!")
        }).catch((error) => {
            setOpenError(true);
            setShowMessage("Failed to publish property !")
        })
    }

    return loading ? (<div className="row featured portfolio-items"> Loading ...</div>) : (

        <div className="col-lg-9 col-md-12 col-xs-12 pl-0 user-dash2">

            <div className="my-properties">
                <table className="table-responsive">
                    <thead>
                        <tr>
                            <th className="pl-2">My Properties</th>
                            <th className="p-0"></th>
                            <th>Feature</th>
                            <th>Published</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listProperties.map((property: any, index: any) => {
                            return <tr key={property._id}>
                                <td className="image myelist">
                                    <a href={`/property/${property._id}`}><img alt="my-properties-3" src={property.content.images[0]} className="img-fluid" /></a>
                                </td>
                                <td>
                                    <div className="inner">
                                        <a href={`/property/${property._id}`}><h2>{property.content.title}</h2></a>
                                        <figure><i className="lni-map-marker"></i> {property.content.Address.city + "," + property.content.Address.community + "," + property.content.Address.subCommunity}</figure>

                                    </div>
                                </td>
                                <td >
                                   <CheckboxListing property={property}/>
                                </td>
                                <td >
                                    {property.publish.toString()}
                                </td>

                                <td className="actions">
                                    <UpdateProperty content={property.content} propertyId={property._id} onUpdateProperty={UpdatePropertyHandler} />
                                    <a style={{ color: "#1d293e", marginRight: "10px", cursor: "pointer" }} onClick={() => publishProperty(property._id)}><i className="fas fa-share" ></i></a>
                                    <a onClick={() => dropProperty(property._id)} style={{ cursor: "pointer" }}><i className="far fa-trash-alt"></i> </a>
                                </td>
                            </tr>
                        })}

                    </tbody>
                </table>
                <div className="pagination-container">
                    {listProperties.length !== 0 ? <Pagination count={page} variant="outlined" shape="rounded" style={{ marginTop: "25px" }} onChange={handleChangePagination} />
                        : <p style={{ textAlign: "center", marginTop: "40px" }}>No listing</p>
                    }
                </div>
            </div>
            <Dialog
                open={openDrop}
                keepMounted
                onClose={handleCloseDrop}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this property?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDrop}> No</Button>
                    <Button onClick={drop}>Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openPublish}
                keepMounted
                onClose={handleClosePublish}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to publish this property?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePublish}> No</Button>
                    <Button onClick={Publish}>Yes</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="success"

                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {showMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={openError} autoHideDuration={6000} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }} onClose={handleCloseError}>
                <Alert
                    onClose={handleCloseError}
                    severity="error"

                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {showMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}
export default Listing;