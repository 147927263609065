import { Route, Routes } from 'react-router-dom';
import Layout from '../Component/Layout/layout';
import Listing from '../Component/listing';
import Add_Agent from '../Component/add_agent';
import Property from '../Component/property';
import UserProfile from '../Component/user-profile';
import AddProperty from '../Component/add-property';


const PublicRouter: React.FunctionComponent<{}> = (props) => {

    return (
        <>
            <Routes>
                <Route path='/' element={<Layout />} >
                    <Route path="" element={<Listing />} />
                    <Route path="/properties" element={<Listing />} />
                    <Route path="/add-property" element={<AddProperty />} />
                    <Route path="/add-Agent" element={<Add_Agent />} />
                    <Route path="/property/:id" element={<Property />} />
                    <Route path="/user-profile" element={<UserProfile />} />
                </Route>
            </Routes>
        </>
    )
}
export default PublicRouter;