import * as React from 'react';



const Header: React.FunctionComponent<{}> = () => {


    return (
            <header id="header-container" className="db-top-header">
                <div id="header">
                    <div className="container-fluid">
                     
                        <div className="header-user-menu user-menu">
                            <div className="header-user-name">
                                <span><img src="/assets/images/testimonials/ts-1.jpg" alt="" /></span>Hi, Mary!
                            </div>
                            <ul>
                                <li><a href="#">Log Out</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
    )
}

export default Header