import React, { useState } from 'react';

const CheckboxListing: React.FunctionComponent<{ property: any }> = (props) => {
    const [checked, setChecked] = useState<boolean>(props.property.featured||false);

    const handleChangeFeature = (event: React.ChangeEvent<HTMLInputElement>, id: any) => {
        setChecked(event.target.checked);
        if (event.target.checked) {
            console.log(` property ID: ${id}`);
        }
    };

    return (
        <input
            type="checkbox"
            checked={checked}
            onChange={(event) => handleChangeFeature(event, props.property._id)}
        />
    );
};

export default CheckboxListing;
