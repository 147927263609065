import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import "leaflet/dist/leaflet.css";
import {useRef } from 'react';
import { format } from 'date-fns';


let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const PropertyDetails: React.FunctionComponent<{ property: any }> = (props) => {

    const mapRef = useRef<any>(null);

    return (
        <>
            <div className="single homes-content details mb-30">

                <h5 className="mb-4">Property Details</h5>
                 <ul className="homes-list clearfix">
                    <li>
                        <span className="font-weight-bold mr-1">Property ID:</span>
                        <span className="det">{props.property?.id}</span>
                    </li>
                    <li>
                        <span className="font-weight-bold mr-1">Property Type:</span>
                        <span className="det">{props.property?.content?.category.name}</span>
                    </li>
                    <li>
                        <span className="font-weight-bold mr-1">Property status:</span>
                        <span className="det">{props.property?.content?.offeringType.replace(/-/g, ' ').replace(/\b\w/g,(c:any) => c.toUpperCase())}</span>
                    </li>
                    <li>
                        <span className="font-weight-bold mr-1">Property Price:</span>
                        <span className="det">{props.property?.content?.price}</span>
                    </li>
                    <li>
                        <span className="font-weight-bold mr-1">Bedrooms:</span>
                        <span className="det">{props.property?.content?.bedrooms_count}</span>
                    </li>
                    <li>
                        <span className="font-weight-bold mr-1">Bathrooms:</span>
                        <span className="det">{props.property?.content?.bathrooms_count}</span>
                    </li>

                    <li>
                        <span className="font-weight-bold mr-1">Year Built:</span>
                        <span className="det">{format(props.property?.date_inserted, 'dd-MM-yyyy')}</span>
                    </li>
                </ul>

                 {props.property?.content.amenities.length !== 0 && <> <h5 className="mt-5">Amenities</h5>

                    <ul className="homes-list clearfix">
                        {props.property?.content && props.property?.content.amenities?.map((obj: any, index: any) => {
                            return <li>
                                <i className="fa fa-check-square" aria-hidden="true"></i>
                                <span>{obj}</span>
                            </li>
                        })}
                    </ul>
                </>}
            </div>
           {props.property?.content.floorplans.length !== 0 && <div className="floor-plan property wprt-image-video w50 pro">
                <h5>Floor Plans</h5>
                {props.property?.content?.floorplans && <img alt="image" src={props.property?.content.floorplans[0]} />}
            </div>} 
            <div className="property-location map">
                <h5>Location</h5>
                <div className="map-container">
                    {props.property?.content?.Address?.longitude && props.property?.content?.Address?.latitude && <MapContainer style={{ height: 400 }} center={[props.property?.content?.Address?.latitude, props.property?.content?.Address?.longitude]} zoom={13} ref={mapRef}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[props.property?.content?.Address?.latitude, props.property?.content?.Address?.longitude]}>
                            {props.property?.content?.Address && <Popup>
                                {props.property?.content?.Address?.city},{props.property?.content?.Address?.community},{props.property?.content?.Address?.subCommunity}
                            </Popup>}
                        </Marker>
                    </MapContainer>}
                </div>
            </div>
        </>
    )
}
export default PropertyDetails;