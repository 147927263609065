import axios from "axios";

const Url = process.env.REACT_APP_URl;



  export const GetPropertyDetails = async (id: any,) => {
    const apiUrl = Url + "/"+id;
    const response = await axios.get(apiUrl,{
      headers: {
        "Authorization": "Bearer "+""
      }
    })
    return response.data;
  }
  export const getLocations=async(locationName: any)=>{
    const apiUrl =  "https://www.api-v1.coralytics.com/locations/autocomplete";
    const response = await axios.post(apiUrl,{locationName :locationName},{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }
  export const getLocationsFind=async(location:any)=>{
    const apiUrl =   "https://www.api-v1.coralytics.com/locations/find";
    const response = await axios.post(apiUrl,{location:location},{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }
  export const getAgentSearch=async(location:any)=>{
    const apiUrl =  Url +"/agentsSearch";
    const response = await axios.post(apiUrl,{q:location},{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }
  
  export const PublishService=async(id:any)=>{
    const apiUrl =  Url +"/publish";
    const response = await axios.put(`${apiUrl}/${id}`,"",{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }
  export const getAgentsFind=async(location:any)=>{
    const apiUrl =  Url +"/agentsfind";
    const response = await axios.post(apiUrl,{q:location},{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }
  export const Addproperty=async(location:any)=>{
    const apiUrl =  Url +"/create_manualy_property";
    const response = await axios.post(apiUrl,location,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }
  export const FilterPropertiesService = async (page :any,properties: any,) => {
    const apiUrl = Url + "/paginate";
    const response = await axios.post(apiUrl,{ "page": page, "limit": 10, "filters": properties},{
      headers: {
        "Authorization": "Bearer "+""
      }
    })
    return response.data;
  }
  
  export const DropProperties = async (id: any) => {
    const apiUrl = Url + "/properties";
    const response = await axios.delete(`${apiUrl}/${id}`,{
      headers: {
        "Authorization": "Bearer "+localStorage.getItem("usertoken")
      }
    })
    return response.data;
  }