import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetPropertyDetails } from "../Services/Serv-properties";
import PropertyDetails from "./property-details";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import AgentInformation from "./agent-information";

const Property: React.FunctionComponent<{}> = () => {

    let location = useLocation();
    const idValue = location.pathname.split('/');
    const [loading, setLoading] = useState(true);
    const [property, setProperty] = useState<any>({});

    useEffect(() => {
        const id: any = idValue[2];
        GetPropertyDetails(id).then((data) => {
            setLoading(false)
            setProperty(data)
        }).catch((error) => {
            console.log("error")
        })

    }, [])
 
    return loading ? (<div className="inner-pages sin-1 homepage-4 hd-white"> Loading ...</div>) : (
        <div className="col-lg-9 col-md-12 col-xs-12 pl-0 user-dash2">
            <div className="inner-pages sin-1 homepage-4 hd-white">
                <section className="single-proper blog details">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12 blog-pots">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div id="listingDetailsSlider" className="carousel listing-details-sliders slide mb-30">
                                            <div className="carousel-inner">

                                                {property?.content?.images && <div className="sliderProp">
                                                    <ImageGallery items={property?.content?.images.map((img: any) => ({ "original": img, "thumbnail": img }))}
                                                        showThumbnails={true}
                                                        showPlayButton={false}
                                                    />
                                                </div>}
                                                <div className="headings-2 pt-0 mt-4">
                                                    <div className="row pro-wrapper">
                                                        <div className="col-md-12 detail-wrapper-body">
                                                            <div className="listing-title-bar">
                                                                <h3>{property?.content?.title} <span className="mrg-l-5 category-tag"> {property?.content?.offeringType.replace(/-/g, ' ').replace(/\b\w/g,(c:any) => c.toUpperCase())}</span></h3>
                                                                <div className="mt-0">
                                                                    <a  className="listing-address">
                                                                        <i className="fa fa-map-marker pr-2 ti-location-pin mrg-r-5"></i>{property?.content?.Address?.subCommunity},{property?.content?.Address?.community},{property?.content?.Address?.city}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 detail-wrapper mt-3">
                                                            <div className="detail-wrapper-body">
                                                                <div className="row listing-title-bar">
                                                                    <div className="col-md-6 mt-0">
                                                                        <a className="listing-address">
                                                                         {/* fas fa-border-all */}
                                                                            <p> <i className=" flaticon-square pr-2 ti-location-pin mrg-r-5" style={{color:"#FF385C"}}></i>{property?.content?.size}</p>
                                                                        </a>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                    <h4 style={{float:"right"}}><i className="fas fa-money-bill pr-2 ti-location-pin mrg-r-5"></i>{property?.content?.price}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="blog-info details mb-30">
                                            <h5 className="mb-4">Description</h5>
                                            <p className="mb-3">{property?.content?.desc}</p>
                                        </div>
                                    </div>
                                </div>
                               <PropertyDetails property={property} /> 
                            </div>
                             <AgentInformation agent={property?.agent} /> 
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
export default Property;