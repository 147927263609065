import axios from "axios";

const Url = process.env.REACT_APP_URl;

export const GetAccount = () => {
    const apiUrl=Url+"/get_account";
     return axios.get(apiUrl,{
       headers: {
         "Authorization": "Bearer "+localStorage.getItem("usertoken")
       }}).then((response) => {
     return response.data;
     }).catch((error)=>{
      console.log(error.data)
     })
  }

  export const AddAgentService = (profile :any) => {
    const apiUrl=Url+"/addagent";
     return axios.post(apiUrl,profile).then((response) => {
     return response.data;
     })
   
  }

 