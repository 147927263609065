import React, { useState } from "react";
import { ProfileAgent } from "../Models/agent";
import { AddAgentService } from "../Services/serv-Agent";
import { Alert, Snackbar } from "@mui/material";


const Add_Agent: React.FunctionComponent<{}> = (props) => {

    const [agentValue, setAgentValue] = useState<ProfileAgent>({});
    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [showMessage, setShowMessage] = useState<any>();

    const handleChangeFirstName = (event: any) => {
        setAgentValue((prevOptions: any) => ({
            ...prevOptions,
            first_name: event.target.value,
        }));
    }

    const handleChangeEmail = (event: any) => {
        setAgentValue((prevOptions: any) => ({
            ...prevOptions,
            email: event.target.value,
        }));
    }

    const handleChangeProfilePicture = (event: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            const base_64 = reader.result as string;

            setAgentValue(prevOptions => ({
                ...prevOptions,
                profile_picture: base_64,
            }));
        }
    }

    const handleChangeLastName = (event: any) => {
        setAgentValue((prevOptions: any) => ({
            ...prevOptions,
            last_name: event.target.value,
        }));
    }

    const handleChangePhoneNumber = (event: any) => {
        setAgentValue((prevOptions: any) => ({
            ...prevOptions,
            phone_number: event.target.value,
        }));
    }
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleCloseError = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    };

    const CreateAgent = () => {
        AddAgentService(agentValue).then((resp) => {
            setOpen(true);
            setShowMessage("Agent created successfully!")
        }).catch((error) => {
            setOpenError(true);
            setShowMessage("Failed to create agent !")
        })
    }

    return (
        <div className="col-lg-9 col-md-12 col-xs-12 royal-add-property-area section_100 pl-0 user-dash2">
            <div className="single-add-property property-form-group">
                <h3>Add Agent</h3>
                <div className="section-inforamation">
                    <div className="row col-users">
                        <div className="col-4  mb-3">
                            <div className="form-group">
                                <label>Profile Picture</label> <br />
                                <img className="mb-2 mt-2 profile" src={agentValue.profile_picture ? agentValue.profile_picture : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8QpKHeBbrELrNRa-63gDAsBM2TQR3GzSxCYwMw73LVw&s"} /><br />
                                <label htmlFor="profilePictureInput" className="upload-image">
                                    Upload image
                                </label>
                                <input
                                    id="profilePictureInput"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleChangeProfilePicture}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <p>
                                <label>First Name</label>
                                <input type="text" placeholder="Enter your First name" value={agentValue?.first_name} onChange={handleChangeFirstName} />
                            </p>
                        </div>
                        <div className="col-sm-6">
                            <p>
                                <label>Last Name</label>
                                <input type="text" placeholder="Enter your Last name" value={agentValue?.last_name} onChange={handleChangeLastName} />
                            </p>
                        </div>
                        <div className="col-sm-6">
                            <p>
                                <label>Email Address</label>
                                <input type="text" placeholder="Ex: example@domain.com" value={agentValue?.email} onChange={handleChangeEmail} />
                            </p>
                        </div>
                        <div className="col-sm-6">
                            <p>
                                <label>Phone Number</label>
                                <input type="text" placeholder="Ex: +1-800-7700-00" value={agentValue?.phone_number} onChange={handleChangePhoneNumber} />
                            </p>
                        </div>
                    </div>
                    <button type="submit" className={`btn btn-primary btn-lg mt-2 ${agentValue?.first_name && agentValue?.email && agentValue?.last_name}`} onClick={CreateAgent}>Save</button>
                </div>
            </div>

            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="success"

                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {showMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={openError} autoHideDuration={6000} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }} onClose={handleCloseError}>
                <Alert
                    onClose={handleCloseError}
                    severity="error"

                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {showMessage}
                </Alert>
            </Snackbar>
        </div>

    )
}
export default Add_Agent
